import React from "react"
import NewsList from "../../common/NewsList"

const Main = ({ data }) => {
  return (
    <section className="p-common-news p-news__detail-news">
      <div className="p-common-news__wrapper">
        <div className="p-common-news__body">
          {data.allContentfulNews.edges.map(edge => (
            <NewsList key={edge.node.slug} news={edge.node} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Main
