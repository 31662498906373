import React from "react"

const Kv = ({ page, headTtl }) => {
  return (
    <section className={`p-common-kv p-${page}-kv`}>
      <div className="p-common-kv__wrapper">
        <div className="p-common-kv__head">
          <h1 className="p-common-kv__head-ttl">
            <div dangerouslySetInnerHTML={{ __html: headTtl }} />
          </h1>
        </div>
      </div>
    </section>
  )
}

export default Kv
