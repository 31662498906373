import Layout from "../../components/common/Layout"
import CareersLink from "../../components/common/CareersLink"
import Footer from "../../components/common/Footer"
import React from "react"
import { graphql } from "gatsby"
import NewsList from "../../components/common/NewsList"
import Kv from "../../components/section/news/Kv"
import Main from "../../components/section/news/Main"

export default function NewsDetail({ data }) {
  let title = "NEWS"
  let description = "ニュース一覧"
  let page = "news"
  let headTtl = "NEWS"
  return (
    <Layout title={title} description={description}>
      <div id="page">
        <div id="news" className="js-page">
          <main id="main">
            <div id="contents" className="contents">
              <Kv page={page} headTtl={headTtl} />
              <Main data={data} />
              <CareersLink />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query allContentfulNews {
    allContentfulNews(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          updatedAt(locale: "ja-JP", formatString: "YYYY.MM.DD")
          publishedAt(locale: "ja-JP", formatString: "YYYY.MM.DD")
          title
          category
          body {
            body
          }
          slug
        }
      }
    }
  }
`
